<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="cancel"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Assign Realtors</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <div class="form-group col-md-12 mt-4 full-width">
              <label for="Realtor_field">Listing Agent</label>
              <multiselect id="LO_field" v-model="selectedListingAgent"
                           :allow-empty="true"
                           :class="{selected: selectedListingAgent !== null && selectedListingAgent !== undefined}"
                           :close-on-select="false"
                           :multiple="true"
                           :max="2"
                           :options="listingAgentsFiltered"
                           :searchable="true"
                           :show-labels="false"
                           track-by="id" label="fullName" class="full-width" placeholder="Select agent">
                <template slot="option" slot-scope="props">
                  {{ props.option.fullName }} {{ props.option.isTC ? '(TC)' : '' }}
                </template>
              </multiselect>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group col-md-12 mt-4 full-width">
              <label for="Realtor_field">Listing Agent TC</label>
              <multiselect id="LO_field" v-model="selectedListingAgentTC"
                           :allow-empty="true"
                           :class="{selected: selectedListingAgentTC !== null && selectedListingAgentTC !== undefined}"
                           :close-on-select="true"
                           :options="listingAgentsTC"
                           :searchable="true"
                           :show-labels="false"
                           track-by="id" label="fullName" class="full-width" placeholder="Select agent">
                <template slot="option" slot-scope="props">
                  {{ props.option.fullName }} {{ props.option.isTC ? '(TC)' : '' }}
                </template>
              </multiselect>
            </div>
          </div>
          <div class="col-6 mt-3">
            <button class="btn btn-outline-dark px-4" type="button" @click="ui.showAddNewListingAgent = !ui.showAddNewListingAgent">
              Add new listing agent
            </button>
          </div>
        </div>
        <div class="mt-3" v-if="ui.showAddNewListingAgent">
          <div class="row">
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">First Name</label>
                <input id="fieldFirstName" v-model="listingAgentFormData.firstName"
                       :class="{ 'input-filled': listingAgentFormData.firstName !== '' }"
                       class="form-control" placeholder="First Name" type="text"/>
              </div>
            </div>
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Last Name</label>
                <input id="fieldLastName" v-model="listingAgentFormData.lastName"
                       :class="{ 'input-filled': listingAgentFormData.lastName !== '' }"
                       class="form-control" placeholder="Last Name" type="text"/>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Email</label>
                <input id="fieldEmail" v-model="listingAgentFormData.email"
                       :class="{ 'input-filled': listingAgentFormData.firstName !== '' }"
                       class="form-control" placeholder="Email" type="email"/>
              </div>
            </div>
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Phone</label>
                <input id="fieldPhone" v-model="listingAgentFormData.phone"
                       :class="{ 'input-filled': listingAgentFormData.phone !== '' }"  v-mask="'(###) ###-####'"
                       class="form-control" placeholder="Phone" type="text"/>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="full-width form-check">
                <div>
                  <input id="fieldIsTC" v-model="listingAgentFormData.isTC" class="form-check-input" type="checkbox"/>
                </div>
                <label class="mb-1" for="fieldIsTC">Is Transaction Coordinator?</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="d-flex justify-content-end">
                <button class="btn btn-outline-dark px-4 me-2" type="button" @click="ui.showAddNewListingAgent = false">
                  Cancel
                </button>
                <base-button title="Add new agent" action="secondary-default" type="submit"
                             @click-btn="storeListingAgent" :loading="ui.btnAddNewAgentLoading" :disabled="!isListingAgentFilled" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <div class="form-group col-md-12 mt-4 full-width">
              <label for="Realtor_field">Buying Agent</label>
              <multiselect id="LO_field" v-model="selectedBuyingAgent"
                           :allow-empty="true"
                           :class="{selected: selectedBuyingAgent !== null && selectedBuyingAgent !== undefined}"
                           :options="buyingAgentsFiltered"
                           :searchable="true"
                           :show-labels="false"
                           :close-on-select="false"
                           :multiple="true"
                           :max="2"
                           track-by="id" label="fullName" class="full-width" placeholder="Select agent">
                <template slot="option" slot-scope="props">
                  {{ props.option.fullName }} {{ props.option.isTC ? '(TC)' : '' }}
                </template>
              </multiselect>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group col-md-12 mt-4 full-width">
              <label for="Realtor_field">Buying Agent TC</label>
              <multiselect id="LO_field" v-model="selectedBuyingAgentTC"
                           :allow-empty="true"
                           :class="{selected: selectedBuyingAgentTC !== null && selectedBuyingAgentTC !== undefined}"
                           :close-on-select="true"
                           :options="buyingAgentsTC"
                           :searchable="true"
                           :show-labels="false"
                           track-by="id" label="fullName" class="full-width" placeholder="Select agent">
                <template slot="option" slot-scope="props">
                  {{ props.option.fullName }} {{ props.option.isTC ? '(TC)' : '' }}
                </template>
              </multiselect>
            </div>
          </div>
          <div class="col-6 mt-3">
            <button class="btn btn-outline-dark px-4" type="button" @click="ui.showAddNewBuyingAgent = !ui.showAddNewBuyingAgent">
              Add new buying agent
            </button>
          </div>
        </div>
        <div class="mt-3" v-if="ui.showAddNewBuyingAgent">
          <div class="row">
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">First Name</label>
                <input id="fieldFirstName" v-model="buyingAgentFormData.firstName"
                       :class="{ 'input-filled': buyingAgentFormData.firstName !== '' }"
                       class="form-control" placeholder="First Name" type="text"/>
              </div>
            </div>
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Last Name</label>
                <input id="fieldLastName" v-model="buyingAgentFormData.lastName"
                       :class="{ 'input-filled': buyingAgentFormData.lastName !== '' }"
                       class="form-control" placeholder="Last Name" type="text"/>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Email</label>
                <input id="fieldEmail" v-model="buyingAgentFormData.email"
                       :class="{ 'input-filled': buyingAgentFormData.firstName !== '' }"
                       class="form-control" placeholder="Email" type="email"/>
              </div>
            </div>
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Phone</label>
                <input id="fieldPhone" v-model="buyingAgentFormData.phone"
                       :class="{ 'input-filled': buyingAgentFormData.phone !== '' }"  v-mask="'(###) ###-####'"
                       class="form-control" placeholder="Phone" type="text"/>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="full-width form-check">
                <div>
                  <input id="fieldIsTC" v-model="buyingAgentFormData.isTC" class="form-check-input" type="checkbox"/>
                </div>
                <label class="mb-1" for="fieldIsTC">Is Transaction Coordinator?</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="d-flex justify-content-end">
                <button class="btn btn-outline-dark px-4 me-2" type="button" @click="ui.showAddNewBuyingAgent = false">
                  Cancel
                </button>
                <base-button title="Add new agent" action="secondary-default" type="submit"
                             @click-btn="storeRealtor" :loading="ui.btnAddNewBuyingAgentLoading" :disabled="!isBuyingAgentFilled" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-dark px-4" type="button" @click="cancel">
          Cancel
        </button>
        <button class="btn btn-primary px-4" type="button" @click="close">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BaseButton from "../../../components/BaseButton.vue";

export default {
  name: 'AssignRealtorsModal',
  components: {BaseButton, Multiselect},
  props: {
    listingAgents: {
      type: Array,
      default: () => []
    },
    buyingAgents: {
      type: Array,
      default: () => []
    },
    listingAgent: {
      type: Array,
      default: () => null
    },
    buyingAgent: {
      type: Array,
      default: () => null
    },
    loanId: {
      type: Number,
      default: () => null
    }
  },
  data() {
    return {
      listingAgentsFiltered: [],
      buyingAgentsFiltered: [],
      listingAgentsTC: [],
      buyingAgentsTC: [],
      selectedListingAgent: null,
      selectedBuyingAgent: null,
      selectedListingAgentTC: null,
      selectedBuyingAgentTC: null,
      ui: {
        showAddNewListingAgent: false,
        showAddNewBuyingAgent: false,
        btnAddNewAgentLoading: false,
        btnAddNewBuyingAgentLoading: false,
      },
      listingAgentFormData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isTC: false,
      },
      buyingAgentFormData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isTC: false
      },
      loan: {}
    }
  },
  beforeMount() {
    if (this.loanId !== null) {
      this.loadRealtors();
    } else {
      this.listingAgentsFiltered = this.listingAgents.filter(agent => agent.isTC === false);
      this.listingAgentsTC = this.listingAgents.filter(agent => agent.isTC === true);
      this.buyingAgentsFiltered = this.buyingAgents.filter(agent => agent.isTC === false);
      this.buyingAgentsTC = this.buyingAgents.filter(agent => agent.isTC === true);

      this.selectedListingAgent = this.listingAgent ? this.listingAgent.filter(agent => agent?.isTC === false) : null
      this.selectedBuyingAgent = this.buyingAgent ? this.buyingAgent.filter(agent => agent?.isTC === false) : null;
      this.selectedListingAgentTC = this.listingAgent ? this.listingAgent.filter(agent => agent?.isTC === true)[0] : null;
      this.selectedBuyingAgentTC = this.buyingAgent ? this.buyingAgent.filter(agent => agent?.isTC === true)[0] : null;
    }
  },
  computed: {
    isListingAgentFilled() {
      return this.listingAgentFormData.firstName !== '' && this.listingAgentFormData.lastName !== '' && this.listingAgentFormData.email !== '' && this.listingAgentFormData.phone !== '';
    },
    isBuyingAgentFilled() {
      return this.buyingAgentFormData.firstName !== '' && this.buyingAgentFormData.lastName !== '' && this.buyingAgentFormData.email !== '' && this.buyingAgentFormData.phone !== '';
    },
  },
  methods: {
    storeListingAgent() {
      this.ui.btnAddNewAgentLoading = true;
      let formData = new FormData();
      formData.append('firstName', this.listingAgentFormData.firstName);
      formData.append('lastName', this.listingAgentFormData.lastName);
      formData.append('email', this.listingAgentFormData.email);
      formData.append('phone', this.listingAgentFormData.phone);
      formData.append('isTC', this.listingAgentFormData.isTC);
      this.$http.post('/api/v1/listing-agents/new', formData)
        .then((response) => {
          const data = response.data
          if (data.realtor) {
            data.realtor.isTC ? this.listingAgentsTC.push(data.realtor) : this.listingAgentsFiltered.push(data.realtor);
            data.realtor.isTC ? this.selectedListingAgentTC = data.realtor : this.selectedListingAgent.push(data.realtor);
          }
          this.listingAgentFormData.firstName = '';
          this.listingAgentFormData.lastName = '';
          this.listingAgentFormData.email = '';
          this.listingAgentFormData.phone = '';
          this.listingAgentFormData.isTC = '';
          this.ui.btnAddNewAgentLoading = false;
          this.ui.showAddNewListingAgent = false;
        })
        .catch(() => {
          this.ui.btnAddNewAgentLoading = false;
        })
    },
    storeRealtor() {
      this.ui.btnAddNewBuyingAgentLoading = true;
      let formData = new FormData();
      formData.append('firstName', this.buyingAgentFormData.firstName);
      formData.append('lastName', this.buyingAgentFormData.lastName);
      formData.append('email', this.buyingAgentFormData.email);
      formData.append('phone', this.buyingAgentFormData.phone);
      formData.append('isTC', this.buyingAgentFormData.isTC);
      this.$http.post('/api/v1/buying-agents/new', formData)
        .then((response) => {
          if (response.data.buyingAgent) {
            response.data.buyingAgent.isTC ? this.buyingAgentsTC.push(response.data.buyingAgent) : this.buyingAgentsFiltered.push(response.data.buyingAgent);
            response.data.buyingAgent.isTC ? this.selectedBuyingAgentTC = response.data.buyingAgent : this.selectedBuyingAgent.push(response.data.buyingAgent);
          }
          this.buyingAgentFormData.firstName = '';
          this.buyingAgentFormData.lastName = '';
          this.buyingAgentFormData.email = '';
          this.buyingAgentFormData.phone = '';
          this.buyingAgentFormData.isTC = '';
          this.ui.btnAddNewBuyingAgentLoading = false;
          this.ui.showAddNewBuyingAgent = false;
        })
        .catch(() => {
          this.ui.btnAddNewBuyingAgentLoading = false;
        })
    },
    loadRealtors() {
      this.$http.get(`/api/v1/loans/get-loan-realtors/${this.loanId}`)
        .then(res => {
          this.listingAgentsFiltered = res.data.listingAgents.filter(agent => agent.isTC === false);
          this.listingAgentsTC = res.data.listingAgents.filter(agent => agent.isTC === true);
          this.buyingAgentsFiltered = res.data.buyingAgents.filter(agent => agent.isTC === false);
          this.buyingAgentsTC = res.data.buyingAgents.filter(agent => agent.isTC === true);

          this.selectedListingAgent = res.data.loanListingAgents.filter(agent => agent?.isTC === false);
          this.selectedBuyingAgent = res.data.loanBuyingAgents.filter(agent => agent?.isTC === false);
          this.selectedListingAgentTC = res.data.loanListingAgents.filter(agent => agent?.isTC === true)[0];
          this.selectedBuyingAgentTC = res.data.loanBuyingAgents.filter(agent => agent?.isTC === true)[0];
        })
    },
    saveRealtors(data) {
      const formData = new FormData();

      for (let agent of data.listingAgents) {
        if (agent !== undefined) {
          formData.append('listingAgents[]', JSON.stringify(agent.id))
        }
      }

      for (let agent of data.buyingAgents) {
        if (agent !== undefined) {
          formData.append('buyingAgents[]', JSON.stringify(agent.id))
        }
      }
      // request to save realtors
      this.$http.post(`/api/v1/loans/edit-loan-realtors/${this.loanId}`, formData)
        .then(res => {
          this.pushAlert('success', 'Realtors successfully changed!')
          this.$emit('close', res);
        })
    },
    close() {
      if (this.loanId !== null) {
        const listingAgents = []
        const buyingAgents = []

        this.selectedListingAgent !== null ? listingAgents.push(this.selectedListingAgent) : null
        this.selectedListingAgentTC !== null ? listingAgents.push(this.selectedListingAgentTC) : null

        this.selectedBuyingAgent !== null ? buyingAgents.push(this.selectedBuyingAgent) : null
        this.selectedBuyingAgentTC !== null ? buyingAgents.push(this.selectedBuyingAgentTC) : null

        const data = {
          listingAgents,
          buyingAgents
        }

        this.saveRealtors(data);
      }
      const data = {
        listingAgent: this.selectedListingAgent,
        buyingAgent: this.selectedBuyingAgent,
        listingAgentTC: this.selectedListingAgentTC,
        buyingAgentTC: this.selectedBuyingAgentTC,
      }

      this.$emit('close', data);
    },
    cancel() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
.form-check {
  display: flex;
  align-items: center;
  margin: 0;
  input.form-check-input {
    height: 18px;
    width: 18px;
    max-width: 18px;
    margin-right: 10px;
  }
  label {
    margin-bottom: 0 !important;
  }
}
.modal {
  .modal-block {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
    margin: 0;
  }
  .modal-body {
    padding: 30px 30px 100px 30px;
    margin-left: 1rem;
    height: 500px;
    overflow-y: auto;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: absolute;
  }
}

.check-block {
  display: flex;
  align-items: center;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 12px;
  width: fit-content;

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}
::v-deep .daterange-dashboard {
  max-width: 100%;
  .reportrange-text {
    line-height: 22px!important;
    max-width: 100%;
  }
  .single.show-ranges .drp-calendar.left {
    border: none!important;
  }
}
::v-deep {
  .multiselect {
    min-height: 36px;
    max-height: 36px;
    min-width: 180px;
    width: 100%;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tag {
      margin: 0 2px;
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    .multiselect__placeholder {
      padding: 0;
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
.form-control {
  height: 36px;
}
</style>
